import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import backgroundVideoMp4 from './video/background.mp4';
import backgroundVideoWebm from './video/background.webm';
import backgroundVideoOgg from './video/background.ogv';
import { SendTransaction } from './SendTransaction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons';

import { faSquareXTwitter, faInstagram, faTiktok, faDiscord, faTelegram, faYoutube } from '@fortawesome/free-brands-svg-icons';


import VideoPoster from './landing/bg-hero.jpg';



const Hero = () => {
    return (
        <Container className='full-height position-relative hero-container ' fluid>
            <div className='bg-blur'></div>
            <video className="background-video" autoPlay loop muted playsInline poster={VideoPoster}>
                <source src={backgroundVideoWebm} type="video/webm" />
                <source src={backgroundVideoOgg} type="video/ogg" />
                <source src={backgroundVideoMp4} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <Container className='container-max-width small-padding'>
                <Row>
                    <Col md={12} lg={12} xl={12} className=' hero-intro text-center-xs flex-center-mobile'>
                        <h1 className='hero-text'>Pre-sale has ended</h1>
                        <h2 className='hero-text'>Follow official channels for updates</h2>
                        {/* <h3>Become part of the elite — backers of  the ETHERNITY deadly game and leaders of the secret world order</h3> */}
                                        <div className="social-icons-hero">
                                            <a href="https://t.me/ethernitygame" target="_blank"><FontAwesomeIcon icon={faTelegram} size="2x" /></a>
                                            <a href="https://twitter.com/0xETHERNITY" target="_blank"><FontAwesomeIcon icon={faSquareXTwitter} size="2x" /></a>
                                            <a href="https://www.instagram.com/avaethernity" target="_blank"><FontAwesomeIcon icon={faInstagram} size="2x" /></a>
                                            <a href="https://www.tiktok.com/@avaethernity" target="_blank"><FontAwesomeIcon icon={faTiktok} size="2x" /></a>
                                            <a href="https://discord.gg/5ze32SFmmS" target="_blank"><FontAwesomeIcon icon={faDiscord} size="2x" /></a>
                                            <a href="https://youtube.com/@ethernitygame" target="_blank"><FontAwesomeIcon icon={faYoutube} size="2x" /></a>
                        
                                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default Hero;
