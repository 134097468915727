import React from 'react';
import Header from './Header';
import Footer from './Footer';
import Hero from './Hero';
import Landing from './Landing';
import PlayButton from './PlayButton';


const Home = () => {
    return (
        <div className="position-relative">
            <Header></Header>
            <Hero></Hero>
        </div>
    );
};

export default Home;